// Dependency
import React, {useState} from "react";
import { graphql } from "gatsby";

// Internationalization
import { useTranslation } from 'react-i18next';

// Components
import Layout from "../../components/layouts";
import hero from "../../images/january-sale.jpg";
import ProductCard from "../../components/cards/product-card";
import ProductCardBig from "../../components/cards/product-card-big";

// Hooks
import ProductList from "../product/hooks/product-list";

// Styles
import * as marqueeStyles from "../../components/marquee.module.scss";
import * as vipStyles from "./vip.module.scss";
import * as shopStyles from "./shop.module.scss";
import * as bwStyles from "./black-week.module.scss"
import Img from "gatsby-image/index";


// Assets

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en" ) {
  datoCmsChristmasDealsPage (locale: {eq: $locale}) {
    title
    seoMetaTags {
      tags
      id
    }
    heroImage{
       fluid{
         ...GatsbyDatoCmsFluid
       }
    }
    bestsellerHeadline
    bestSellers{
      centraId
      id
      title
      desc
      slug
      featuredImage {
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      gallery{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      blackWeekImage {
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      blackWeekGallery{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      locale
      trustpilotId
      productCategory {
        name
        slug
      } 
      infoPerks{
        text
      }
      saleBadge
      bestseller
    }
    moreProductsHeadline
    moreProducts{
      centraId
      id
      title
      parentProductName
      slug
      featuredImage {
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      blackWeekImage {
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      blackWeekGallery{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      locale
      trustpilotId
      productCategory {
        name
        slug
      }
      saleBadge
      bestseller
    }
  }
}
`

// Template
const ChristmasDeals = (props) => {
    // Extract page data
    const pageData = props.data.datoCmsChristmasDealsPage;

    const [productList, setProductList] = useState([]);

    // Hero media
    const mediaData = {
        desktopImg: pageData && pageData.heroImage ? pageData.heroImage.fluid : '',
    }

    // Translation Function
    const { t } = useTranslation();

    return (
        <Layout seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
            <ProductList isCampaign={false} createProductList={setProductList} />

            <div className={`${bwStyles.cdBwHero} ${vipStyles.cdVipHero}`}>
                <Img alt="Christmas Deals" fluid={mediaData.desktopImg} />
                <div className={vipStyles.cdVipHeroContent}>
                    <div className={`${marqueeStyles.cdMarqueeWrapper}`}>
                        <div className={`${marqueeStyles.marquee} ${vipStyles.cdMarqueeVip}`} >
                            <div className={marqueeStyles.marquee__content}>
                                <ul className={marqueeStyles.listInline}>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                    <li>{t('marquee.middleDeals')}</li>
                                    <li><span>{t('marquee.middleDeals')}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${shopStyles.cdShopWrapper} ${bwStyles.cdBwPage}`}>

                <section className={shopStyles.cdShopCatSection}>
                    <div className="cd-max-width">
                        <div className={`${shopStyles.cdShopHeading} ${bwStyles.cdBwHeading} ${bwStyles.cdCdHeading}`}>
                            {pageData && pageData.bestsellerHeadline && <h2>{pageData.bestsellerHeadline}</h2>}
                            <span></span>
                        </div>
                    </div>
                    <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
                        {
                            pageData.bestSellers && pageData.bestSellers.map( (element, index) => {
                                    let prodFilter = productList.filter((prod) => {
                                        return element.centraId.toString() === prod.centraProduct;
                                    })
                                    let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                                    return (
                                        <div key={index} className={bwStyles.cdProductCard}>
                                            <ProductCardBig cname={"cd-bw-card cd-cd-card"} centraProd={centraProd} key={element.id} data={element} />
                                        </div>
                                    )
                                }
                            )}
                    </div>
                </section>

                <section className={shopStyles.cdShopCatSection}>
                    <div className="cd-max-width">
                        <div className={`${shopStyles.cdShopHeading} ${bwStyles.cdBwHeading} ${bwStyles.cdCdHeading}`}>
                            {pageData && pageData.moreProductsHeadline && <h2>{pageData.moreProductsHeadline}</h2>}
                            <span></span>
                        </div>
                    </div>
                    <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
                        {
                            pageData.moreProducts && pageData.moreProducts.map( (element, index) => {
                                    let prodFilter = productList.filter((prod) => {
                                        return element.centraId.toString() === prod.centraProduct;
                                    })
                                    let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                                    return (
                                        <div key={index} className={shopStyles.cdShopProductCard}>
                                            <ProductCard dataTheme={"dark"} cname={"cd-bw-card cd-cd-card"} centraProd={centraProd} key={element.id} data={element} />
                                        </div>
                                    )
                                }
                            )}
                    </div>
                </section>

            </div>
        </Layout>
    )
}

export default ChristmasDeals